import React from 'react';
import styled, {css} from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {AD_SLOT_HEIGHT, getAdContainerHeight} from 'app/components/ads/adHelpers';
import {useAdRemount} from 'app/components/ads/useAdRemount';
import {useHydration} from 'app/hooks/useHydration';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {AdSlotContainer} from 'app/components/ads/AdSlotContainer';
import {IS_CHROME, PARALLAX_AD_UNITS} from 'app/components/ads/ad.constants';
import {useNative} from 'app/contexts/NativeContext';

const slotsWithoutMargin = ['MatchEvent_1', 'Native_2'];

const SCAdSlotContainer = styled(AdSlotContainer)`
  display: ${props => (props.adLayout === 'desktop' ? 'none' : 'block')};
  background: ${COLORS.white};
  margin: ${props => props.margin};
  width: 100%;
  z-index: 0;
  justify-content: center;
  align-items: center;
  height: ${props => props.mobileHeight};
  overflow: hidden;
  ${props =>
    props.isNative_1 &&
    css`
      max-width: ${props => props.theme.mobileContentWidth}px;
    `};
  ${props =>
    props.setMinHeight &&
    css`
      min-height: ${props => props.setMinHeight}px;
    `};

  ${props => props.theme.desktopLayout`
    display: ${props => (props.adLayout === 'mobile' ? 'none' : 'block')};
    height: ${props => props.desktopHeight};
    background: ${COLORS.white};
    ${props =>
      props.isNative_1 &&
      css`
        height: 100%;
        margin: 0 0 0.75rem;
        border: 1px solid ${COLORS.lightGrey};
        border-radius: ${SIZES[4]};
        max-width: ${props => props.theme.elementsWidth2Columns}px;
      `};
  `};
`;

const types = [
  'Content_1',
  'Content_2',
  'Rectangle_1',
  'Rectangle_2',
  'Native_1',
  'Native_2',
  'Superbanner',
  'Superbanner_2',
  'Superbanner_3',
  'Skyscraper',
  'Skyscraper_2',
  'Sticky_Mobile_Banner',
  'Mobile_Banner',
  'EdW_Banner_Top',
  'EdW_Banner_Bottom',
  'EdW_Banner_Center',
  'MatchEvent_1',
  'Sponsor_1',
];

// AdSlot: Component to set styles for wrapper where adSlot can be placed (SSR/CSR independent)
const AdSlot = ({adUnit, limitedDim, adLayout, noMargin = false, ...props}) => {
  if (!types.includes(adUnit)) {
    return null;
  }

  const {isNativeApp} = useNative();

  const isHydrating = useHydration();
  const layoutChange = useIsDesktop(adUnit);
  const disableSlotForNative = props.notOnNative && isNativeApp;

  const isDesktopLayoutWithAds = (layoutChange && !adLayout) || (layoutChange && adLayout === 'desktop');
  const isMobileLayoutWithAds = (!layoutChange && !adLayout) || (!layoutChange && adLayout === 'mobile');

  // use key to redefine adSlots after navigation from routeA to routeB has caused destroying all slots but adSlot get's not unmounted
  // e.g. AdSlot superbanner is placed globally by template and not by route
  const key = useAdRemount();

  const adSlotsHeight = getAdContainerHeight(limitedDim);
  let adHeight = adSlotsHeight[adUnit];
  let setMinHeight;
  let overwriteHeight;

  if (adUnit === 'Native_1' || adUnit === 'MatchEvent_1') {
    // don't set fix height based on defined dimensions for native_1 to avoid cutting of custom fupa advertorial template
    // use min-height and height: auto instead to display correctly
    overwriteHeight = 'auto';
    setMinHeight = adHeight.maxMobileHeight;
  }

  if (
    adUnit === 'Skyscraper' ||
    adUnit === 'Skyscraper_2' ||
    adUnit === 'Superbanner_2' ||
    adUnit === 'Superbanner_3'
  ) {
    // Skyscraper: set min-height of ad container based max height of slot dimensions to enable 1x1 dimension to show creatives independent of 300x600
    // Superbanners: overwrite max-height to enable SoD slider desktop format
    setMinHeight = adHeight.maxDesktopHeight;
    overwriteHeight = 'auto';
  }

  // Disable Parallax for Chrome on Mobile because of wrong viewability measurement
  const parallaxSlots = IS_CHROME ? [] : PARALLAX_AD_UNITS;

  const isParallaxEnabledAdUnit = parallaxSlots.includes(adUnit);
  let mobileHeight = adHeight.maxMobileHeight + 'px';

  if (overwriteHeight) {
    mobileHeight = overwriteHeight;
  } else if (isParallaxEnabledAdUnit) {
    mobileHeight = `${AD_SLOT_HEIGHT}px`;
  }
  const removeMargin = noMargin || slotsWithoutMargin.includes(adUnit);
  const desktopHeight = overwriteHeight ? overwriteHeight : adHeight.maxDesktopHeight + 'px';
  // Show AdPlaceholder with SCAdSlotContainer as div if
  // 1. mediaQueries don't match with adLayout if given (e.g. StreamPages)
  // 2. Hydration is not finished
  // 3. AdSlot is disabled for native app
  // 4. skipSky: AdSlot Superbanner is delivered with skin format
  return (isDesktopLayoutWithAds || isMobileLayoutWithAds) &&
    !isHydrating &&
    !disableSlotForNative &&
    !props.skipSky ? (
    <SCAdSlotContainer
      key={key}
      isNative_1={adUnit === 'Native_1'}
      adLayout={adLayout}
      setMinHeight={setMinHeight}
      mobileHeight={mobileHeight}
      desktopHeight={desktopHeight}
      margin={removeMargin ? 0 : `${SIZES['16']} auto`}
      adUnit={adUnit}
      limitedDim={limitedDim}
      {...props}
    />
  ) : (
    <SCAdSlotContainer
      as='div'
      adLayout={adLayout}
      isNative_1={adUnit === 'Native_1'}
      setMinHeight={setMinHeight}
      mobileHeight={mobileHeight}
      desktopHeight={desktopHeight}
      adUnit={adUnit}
      margin={removeMargin ? 0 : `${SIZES['16']} auto`}
      limitedDim={limitedDim}
      {...props}
    />
  );
};

export {AdSlot};
